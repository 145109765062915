@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/gill-sans-2");
@import url("https://fonts.cdnfonts.com/css/emotional-baggage");

body {
  background-color: #041c40;
  font-family: "Emotional Baggage", sans-serif;
}

.image-div {
  /* background-color: black; */
  background-image: url("./assets/boxy.png");
  background-size: contain; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image in the div */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
.top {
  /* background-color: black; */
  background-image: url("./assets/topground.png");
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: top; /* Centers the image in the div */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
.under {
  /* background-color: black; */
  background-image: url("./assets/underground.png");
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: top; /* Centers the image in the div */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
.headder {
  /* background-color: black; */
  background-image: url("./assets/header.svg");
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: top; /* Centers the image in the div */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

.gill {
  font-family: "Gill Sans", sans-serif;
}
/* Define the keyframes for the rotation */
@keyframes slowRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the element */
.slow-rotate {
  animation: slowRotate 60s linear infinite;
}

/* Define the keyframes for the bobbing animation */
@keyframes slowBob {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* Apply the animation to the element */
.slow-bob {
  animation: slowBob 2s ease-in-out infinite;
}

ONE {
}

/* Define the keyframes for the cloud movement */
@keyframes moveCloud2 {
  0% {
    transform: translateX(-130%);
  }
  100% {
    transform: translateX(130%);
  }
}

/* Apply the animation to the cloud element */
.cloud2 {
  animation: moveCloud2 60s linear infinite;
}

TWO {
}

/* Define the keyframes for the cloud movement */
@keyframes moveCloud1 {
  0% {
    transform: translateX(-130%);
  }
  100% {
    transform: translateX(230%);
  }
}

/* Apply the animation to the cloud element */
.cloud1 {
  animation: moveCloud1 19s linear infinite;
}

THREE {
}

/* Define the keyframes for the cloud movement */
@keyframes moveCloud3 {
  0% {
    transform: translateX(-140%);
  }
  100% {
    transform: translateX(430%);
  }
}

/* Apply the animation to the cloud element */
.cloud3 {
  animation: moveCloud3 30s linear infinite;
}

FOUR {
}

/* Define the keyframes for the cloud movement */
@keyframes moveCloud4 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(430%);
  }
}

/* Apply the animation to the cloud element */
.cloud4 {
  animation: moveCloud4 55s linear infinite;
}

FIVE {
}

/* Define the keyframes for the cloud movement */
@keyframes moveCloud5 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(430%);
  }
}

/* Apply the animation to the cloud element */
.cloud5 {
  animation: moveCloud5 85s linear infinite;
}
